import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import App from './pages/App';
import Produits from './pages/Produits';
import About from './pages/About';
import Error404 from './pages/Error404';
import DashboardAdmin from './pages/dashboard.admin.screen';
import DashboardAdminCategory from './pages/dashboard.admin.category.screen';
import DashboardAdminBrand from './pages/dashboard.admin.brand.screen';
import DashboardAdminCompany from './pages/dashboard.admin.company.screen';
import DashboardAdminArticle from './pages/dashboard.admin.article.screen';
import LoginView from './pages/login.screen';
import RegistrationView from './pages/registration.screen';
import Provider from "./context/provider";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './plugin/font-awesome-4.7.0/css/font-awesome.min.css';

import '@fontsource/inter';

/**importation des styles globals */
import './styles/global/default.scss';
import './styles/fonts/font-style/font.css';
import './styles/global/animation.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
const currentUser = JSON.parse(localStorage.getItem("erepere_user"));

const router_ = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <Error404/>,
    },{
        path: "/:page",
        element: <App/>,
        errorElement: <Error404/>,
    },{
      path: "/:page/:category/:catId",
      element: <App/>,
      errorElement: <Error404/>,
    },{
        path: "/login",
        element: <LoginView/>,
        errorElement: <Error404/>,
    },{
      path: "/registration",
      element: <RegistrationView/>,
      errorElement: <Error404/>,
    },{
        path: "/comparatif",
        element: <Produits/>,
        errorElement: <Error404/>,
    },{
        path: "/about",
        element: <About/>,
        errorElement: <Error404/>,
    },{
        path: "/dashboard/",
        element: <DashboardAdmin/>,
        children: [{
            path: "category",
                element: !currentUser ? <LoginView/> : <DashboardAdminCategory/>
            },{
                path: "category/:page",
                element: !currentUser ? <LoginView/> : <DashboardAdminCategory/>
            },{
                path: "brand",
                element: !currentUser ? <LoginView/> : <DashboardAdminBrand/>
            },{
                path: "brand/:page",
                element: !currentUser ? <LoginView/> : <DashboardAdminBrand/>
            },{
                path: "company",
                element: !currentUser ? <LoginView/> : <DashboardAdminCompany/>
            },{
                path: "company/:page",
                element: !currentUser ? <LoginView/> : <DashboardAdminCompany/>
            },{
                path: "product",
                element: !currentUser ? <LoginView/> : <DashboardAdminArticle/>
            },{
                path: "product/:page",
                element: !currentUser ? <LoginView/> : <DashboardAdminArticle/>
            }
        ]
    }
]);

root.render(
  // <React.StrictMode>
  <Provider>
    <RouterProvider router={router_}/>
  </Provider>
  // </React.StrictMode>
);

