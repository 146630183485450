import React, {useState, useContext, useEffect} from 'react';
import {
  useParams
} from "react-router-dom";

// import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../css/App.css';
import Product from '../components/Product';

// File
import background_video from '../video/backgroud-video.mp4'

import { ContextP } from "../context/provider";


function App() {
  const { page } = useParams();
  const { category } = useParams();
  const { catId } = useParams();

  const {getCATEGORY} = useContext(ContextP);
  const {getARTICLE_BY_CATEGORIES} = useContext(ContextP);
  const {getARTICLE} = useContext(ContextP);

  const [CATEGORY, setCATEGORY] = useState([]);
  const [ARTICLE, setARTICLE] = useState(null);

  function _get_(page) {
    getCATEGORY(2000000,page,(res)=>{
        console.log(res);
        setCATEGORY(res.data.data);
    }, (e)=>{
        return false;
    });
  }

  function _getArticle_byCat(page, category) {
    let data = {
      categories: {
          ni: {
              categories: [category],
          },
      },
    };
    // console.log(data);
    getARTICLE_BY_CATEGORIES(20, page, data, (res)=>{
      setARTICLE(res.data);
    }, (e)=>{
        return false;
    });
  }

  function _getAllArticle_(page) {
    getARTICLE(20, page, (res)=>{
        console.log(res.data);
        setARTICLE(res.data);
    }, (e)=>{
        return false;
    });
  }

  useEffect(() => {
    _get_(1);
    if(page) {
      if(!category) {
        _getAllArticle_(page);
      } else {
        _getArticle_byCat(page, catId);
      }
    } else {
      if(!category) {
        _getAllArticle_(1);
      } else {
        _getArticle_byCat(1, catId);
      }
    }
  }, [setCATEGORY])

  return (
    <div>
      {/* <!-- ======= Header Section ======= --> */}
      <Header active={"accueil"} />
      {/* <!-- End Header --> */}

      {/* <!-- ======= Hero Section ======= --> */}
      <section id="hero" class="d-flex align-items-center">
        <video autoPlay muted loop id="myVideo">
          <source src={background_video} type="video/mp4" />
        </video>
        <div class="container" data-aos="fade-up" data-aos-delay="100">
          <div class="row">
            <div class="col-12">
              <h1>Venez vivre la meilleure expérience de découverte avec nous, les passionnés de la beauté et de la mode
              </h1>
              <h2>Votre ultime destination pour découvrir les derniers produits de beauté et de mode.</h2>
              <a href="#about" class="btn-get-started scrollto">Passer à l'action</a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Hero --> */}

      {/* <!-- ======= Main Section ======= --> */}
      <main id="main" >

        {/* <!-- ======= Plus recherché Section ======= --> */}
        <section id="tabs" class="tabs">
          <div class="section-title">
            <h2>Les plus recherchés</h2>
            <p>Découvrez nos produits les plus recherchés qui font sensation dans le monde de la beauté et de la mode :
            </p>
          </div>
          <div class="container" data-aos="fade-up">
            <ul class="nav nav-tabs row gx-5">
              <li class="col">
                <a href={page ? '/' + page : '/'}>
                  <p class="nav-link active show w-auto mb-2" data-bs-toggle="tab" data-bs-target="#tab-1">
                    <h4 class="d-flex d-lg-block fs-6"> Tout </h4>
                  </p>
                </a>
              </li>
              {/* {
                CATEGORY.map((cat, index) => (
                  <li class="col" key={index + "/" + cat.name}>
                    <a href={page? '/' + page + '/' + cat.name + "/" + cat.id : '/1/' + cat.name + "/" + cat.id}>
                      <p class="nav-link mb-2" data-bs-toggle="tab" data-bs-target="#tab-2">
                        <h4 class="d-flex d-lg-block fs-6"> {cat.name} </h4>
                      </p>
                    </a>
                  </li>
                ))
              } */}
            </ul>
            <div class="tab-content">
              <div class="tab-pane active show" id="tab-1">
                <div id="portfolio" class="portfolio m-0">
                  <div class="container" data-aos="fade-up">
                    <div className="products-list" data-aos="fade-up" data-aos-delay="200">
                      {
                        ARTICLE !== null &&
                        ARTICLE.data.map((element, index) => <div className="product"><Product key={index} nom={element.name} categories={element.categories} brands={element.brands} image={element.features.logoUrl} prix={element.price + " " + element.currency} source={element.sourceUrl}/></div>)
                      }
                    </div>
                  </div>
                </div>
              </div>
              
              {/* <div class="tab-pane" id="tab-2">
                <div id="portfolio" class="portfolio m-0">
                  <div class="container" data-aos="fade-up">
                    <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
                      {
                        listProduit.filter((element => element.categorie === "Beauté/Santé")).map((element, index) => <Product key={index} nom={element.nom} categorie={element.categorie} image={element.image} prix={element.prix} />)
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="tab-3">
                <div id="portfolio" class="portfolio m-0">
                  <div class="container" data-aos="fade-up">
                    <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
                      {
                        listProduit.filter((element => element.categorie === "Mode Femme")).map((element, index) => <Product key={index} nom={element.nom} categorie={element.categorie} image={element.image} prix={element.prix} />)
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="tab-4">
                <div id="portfolio" class="portfolio m-0">
                  <div class="container" data-aos="fade-up">
                    <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
                      {
                        listProduit.filter((element => element.categorie === "Mode Enfant")).map((element, index) => <Product key={index} nom={element.nom} categorie={element.categorie} image={element.image} prix={element.prix} />)
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="tab-5">
                <div id="portfolio" class="portfolio m-0">
                  <div class="container" data-aos="fade-up">
                    <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
                      {
                        listProduit.filter((element => element.categorie === "Mode Homme")).map((element, index) => <Product key={index} nom={element.nom} categorie={element.categorie} image={element.image} prix={element.prix} />)
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="tab-6">
                <div id="portfolio" class="portfolio m-0">
                  <div class="container" data-aos="fade-up">
                    <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
                      {
                        listProduit.filter((element => element.categorie === "Produits Tendances")).map((element, index) => <Product key={index} nom={element.nom} categorie={element.categorie} image={element.image} prix={element.prix} />)
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="tab-7">
                <div id="portfolio" class="portfolio m-0">
                  <div class="container" data-aos="fade-up">
                    <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
                      {
                        listProduit.filter((element => element.categorie === "Meilleures Décos")).map((element, index) => <Product key={index} nom={element.nom} categorie={element.categorie} image={element.image} prix={element.prix} />)
                      }
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div style={{marginBottom: "300px"}}></div>
        </section>
        {/* <!-- End Plus recherché Section --> */}

      </main>
      {/* <!-- End Main --> */}

      {/* <!-- ======= Footer Section ======= --> */}
      <Footer />
      {/* <!-- End Footer --> */}

      <a href="/" class="back-to-top d-flex align-items-center justify-content-center"><i
        class="bi bi-arrow-up-short"></i></a>
    </div>
  );
}

export default App;
