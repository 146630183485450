import React, {useEffect, useState} from 'react';

function Header({ active }) {
    const [isHidden, setIsHidden] = useState(false);
    const [user, setUser] = useState("");

    function handleScroll() {
        const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (currentScrollTop > 50) {
            // Scrolling down and passed the threshold
            setIsHidden(true);
        } else {
            // Scrolling up and passed the threshold
            setIsHidden(false);
        }
    }

    useEffect(()=>{
        window.addEventListener('scroll', handleScroll);
        const currentUser = JSON.parse(localStorage.getItem("snobe_user"));
        setUser(currentUser);
    }, []);

    return (
        <div>
            <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">Toggle bottom offcanvas</button>

            <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasBottomLabel">Offcanvas bottom</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body small">
                    ...
                </div>
            </div>
            <header id="header" class="fixed-top">
                <nav className={"nav top_nav " + (isHidden ? " is_hidden" : " is_visible")}>
                    <div style={{width: "100%", maxWidth: "1150px", margin: "auto", marginTop: "-10px", marginBottom: "-10px"}}>
                        <ul className='top_nav_connection d-flex align-items-center justify-content-end'>
                            {
                                user === null ?
                                    <>
                                        <li>
                                            <a style={{borderRadius: "5px"}} className='button-style-2 connection' href='/login'>
                                                SE CONNECTER
                                            </a>
                                        </li>
                                        <li>
                                            <a style={{borderRadius: "5px"}} className='button-style-2 connection' href='/registration'>
                                                S'INSCRIRE
                                            </a>
                                        </li>
                                    </>
                                :
                                    <>
                                        <li>
                                            <a style={{borderRadius: "5px", textDecoration: "underline", fontSize: "1em"}} className='button-style-2 connection' href='/dashboard'>
                                                Dashboard
                                            </a>
                                        </li>
                                    </>
                            }
                        </ul>
                    </div>
                </nav>
                <div class="container d-flex align-items-center">
                    <h1 class="logo me-auto"><a href="index.html"> Snobe <span>.</span></a></h1>
                    <nav id="navbar" class="navbar order-last order-lg-0">
                        {
                            active === "accueil" ?
                                <ul>
                                    <li><a class="nav-link scrollto active" href="/">Accueil</a></li>
                                    <li><a class="nav-link scrollto" href="/about">À propos</a></li>
                                    <li><a class="nav-link scrollto" href="/comparatif">Comparatifs</a></li>
                                </ul>
                                :
                                active === "aPropos" ?
                                    <ul>
                                        <li><a class="nav-link scrollto" href="/">Accueil</a></li>
                                        <li><a class="nav-link scrollto active" href="/about">À propos</a></li>
                                        <li><a class="nav-link scrollto" href="/comparatif">Comparatifs</a></li>
                                    </ul> :
                                    active === "comparatif" ?
                                        <ul>
                                            <li><a class="nav-link scrollto" href="/">Accueil</a></li>
                                            <li><a class="nav-link scrollto" href="/about">À propos</a></li>
                                            <li><a class="nav-link scrollto active" href="/comparatif">Comparatifs</a></li>
                                        </ul> : null
                        }

                        <i class="bi bi-list mobile-nav-toggle"></i>
                    </nav>
                    <a href="#about" class="get-started-btn scrollto"> Passer à l'action </a>
                </div>

            </header>
        </div>
    )
}

export default Header