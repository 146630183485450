import React, {useState, useContext, useEffect} from 'react';
import {
    useParams
} from "react-router-dom";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Box from "@mui/material/Box";
import { Pagination } from "@mui/material";

import '../styles/dashboard.faq.style.scss';

import { ContextP } from "../context/provider";

import PATH_ from '../GLOBAL-VAR/globals-urls';
const PATH__ = new PATH_();

function DashboardAdminArticle( props ) {
    const { page } = useParams();


    const {getCATEGORY} = useContext(ContextP);
    const {getCOMPANY} = useContext(ContextP);
    const {getBRAND} = useContext(ContextP);
    const {getARTICLE} = useContext(ContextP);
    const {addARTICLE} = useContext(ContextP);
    const {updateARTICLE} = useContext(ContextP);
    const {deleteARTICLE_CATEGORIES} = useContext(ContextP);
    const {deleteARTICLE_BRANDS} = useContext(ContextP);
    
    const {deleteARTICLE} = useContext(ContextP);

    const {addMEDIA} = useContext(ContextP);
    const {deleteMEDIA} = useContext(ContextP);
    const {link_ARTICLE_MEDIA} = useContext(ContextP);
    const {unlink_ARTICLE_MEDIA} = useContext(ContextP);
    
    // const {setALertMessage} = useContext(ContextP);
    // const {setCurrentAlertMessage} = useContext(ContextP);
    // const {setCurrentAlertType} = useContext(ContextP);
    // const {setCurrentAlertAction} = useContext(ContextP);


    const [ARTICLE, setARTICLE] = useState(null);
    const [CATEGORY, setCATEGORY] = useState([]);
    const [COMPANY, setCOMPANY] = useState([]);
    const [BRAND, setBRAND] = useState([]);
    const [MEDIAS, setMEDIAS] = useState([]);
    const [CAT, setCAT] = useState(null);
    const [actionType, setActionType] = useState("add");
    const [currentARTICLE, setCurrentARTICLE] = useState(null);
    // const [currentARTICLECat, setCurrentARTICLECat] = useState([]);

    function _addMedias_(_medias_, ind = 0, article_id, callback=()=>{}) {
        if(ind < _medias_.length) {
            let media = new FormData();
            media.append("alt", _medias_[ind].file.type);
            media.append("description", "");
            media.append("file", _medias_[ind].file);

            addMEDIA(media, (res)=>{
                let data = {
                    label: "",
                    description: "",
                };
                link_ARTICLE_MEDIA(data, article_id, res.data.id, (res)=>{
                    _addMedias_(_medias_, ind+1, article_id, callback);
                }, (e) => {
                    console.log(e);
                })
            }, (e)=>{
                console.log(e);
            })
        } else {
            callback();
        }
    }

    function _add_() {

        let cat = [];
        CATEGORY.map((category)=>{
            if(document.getElementById("cat" + category.name).checked) {
                cat.push(category.id);
            }
            return true;
        });

        let cmp = [];
        COMPANY.map((category)=>{
            if(document.getElementById("company" + category.name).checked) {
                cmp.push(category.id);
            }
            return true;
        });

        let brd = [];
        BRAND.map((category)=>{
            if(document.getElementById("brand" + category.name).checked) {
                brd.push(category.id);
            }
            return true;
        });
        console.log(currentARTICLE, cat, cmp, brd);

        addARTICLE(currentARTICLE, cat, cmp, brd, (res)=>{
            setCurrentARTICLE(null);
                if(page) {
                    _get_(page);
                } else {
                    _get_(1);
                }
        }, (e)=>{
            console.log(e);
        });
    }

    function _update_() {
        if(window.confirm("Voulez-vous vraiment modifier ce produit ?")) {
            let cat = [];
            let catDel = [];

            let brand = [];
            let brandDel = [];

            let cmp = [];
            COMPANY.map((category)=>{
                if(document.getElementById("company" + category.name).checked) {
                    cmp.push(category.id);
                }
                return true;
            });

            
            CATEGORY.map((category)=>{
                if(document.getElementById("cat" + category.name).checked) {
                    cat.push(category.id);
                }else {
                    catDel.push(category.id);
                }
                return true;
            });

            BRAND.map((brand_)=>{
                if(document.getElementById("brand" + brand_.name).checked) {
                    brand.push(brand_.id);
                }else {
                    brandDel.push(brand_.id);
                }
                return true;
            });
            // console.log(cat, cmp, brand);
            updateARTICLE(currentARTICLE.id, currentARTICLE, cat, cmp, brand, (res)=>{
                //si il a des categories a retirer
                if(catDel.length>0) {
                    deleteARTICLE_CATEGORIES(currentARTICLE.id, catDel, (res)=>{
                        // console.log(res);
                        if(page) {
                            _get_(page);
                        } else {
                            _get_(1);
                        }
                    }, (e)=>{
                        console.log(e);
                    });
                }

                if(brandDel.length>0) {
                    deleteARTICLE_BRANDS(currentARTICLE.id, brandDel, (res)=>{
                        // console.log(res);
                        if(page) {
                            _get_(page);
                        } else {
                            _get_(1);
                        }
                    }, (e)=>{
                        console.log(e);
                    });
                }

                if(page) {
                    _get_(page);
                } else {
                    _get_(1);
                }
            }, (e)=>{
                console.log(e);
            });
            
        }
    }

    function _delete_(id) {
        // console.log(id);
        if(window.confirm("Voulez-vous vraiment supprimer cet article ?")) {
            deleteARTICLE(id, (res)=>{
                if(page) {
                    _get_(page);
                } else {
                    _get_(1);
                }
            }, (e)=>{
                console.log(e);
            });
        }
    }

    function _get_(page, cat=[]) {
        getARTICLE(20, page, (res)=>{
            // console.log(res.data);
            setARTICLE(res.data);
        }, (e)=>{
            return false;
        });
    }

    function _get_category(page, call=()=>{}) {
        getCATEGORY(20000,page,(res)=>{
            // console.log(res);
            call(res.data.data);
            setCATEGORY(res.data.data);
        }, (e)=>{
            return false;
        });
    }

    function _get_company(page) {
        getCOMPANY(20,page,(res)=>{
            setCOMPANY(res.data.data);
        }, (e)=>{
            return false;
        });
    }

    function _get_brand(page) {
        getBRAND(20,page,(res)=>{
            // console.log(res);
            setBRAND(res.data.data);
        }, (e)=>{
            return false;
        });
    }

    function _delete_media_selected_(mediaIndex){
        let CA = [...MEDIAS];
        CA.splice(mediaIndex, 1);
        setMEDIAS(CA);
    }

    useEffect(() => {
        _get_category(1, (categories) => { 
            setCAT(categories);
            _get_brand();
            _get_company();
            // console.log(categories);
            if(page) {
                _get_(page);
            } else {
                _get_(1);
            }
        });
    }, [setARTICLE, setCATEGORY])

    return (
        <div className='faq'>
            <div className='faq_content'>
                <div className='faq_content_button'>
                    <button
                        onClick={(e)=>{
                            setCurrentARTICLE({
                                name: "",
                                currency: "XOF",
                                price: 0,
                                sourceUrl: "",
                                evaluation: 0,
                                features: {
                                    'logoUrl': '',
                                },
                            });
                        }}
                    >AJOUTER</button>
                </div>
                <br/>
                <div>
                    <table className='table-classic'>
                        <thead>
                            <tr>
                                <th>NOM</th>
                                <th>IMAGES</th>
                                <th>PRIX</th>
                                <th>SOURCE</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ARTICLE!==null &&
                                ARTICLE.data.map((article, index)=>(
                                    <tr style={{
                                        background: index%2===0 ? "#00000005" : "transparent",
                                    }}>
                                        <td><strong>{article.name}</strong></td>
                                        <td><img src={article.features.logoUrl} alt={article.name}/></td>
                                        <td>{article.price} {article.currency}</td>
                                        <td><strong>{article.sourceUrl}</strong></td>
                                        <td>
                                            <button className='update'
                                                onClick={(e)=>{
                                                    setActionType("update");
                                                    setCurrentARTICLE(article);
                                                }}
                                            >
                                                Modifier
                                            </button>
                                            <button className='delete'
                                                onClick={(e)=>{
                                                    // setCurrentARTICLE(article);
                                                    _delete_(article.id);
                                                }}
                                            >
                                                supprimer
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <br/>
                    <br/>

                    {
                        ARTICLE!==null &&
                        <Box
                            sx={{
                                margin: "auto",
                                width: "fit-content",
                                alignItems: "center",
                            }}
                        >
                            <Pagination count={ARTICLE.totalPage} page={ARTICLE.page} 
                                onChange={(e, value)=>{
                                    _get_(value, CAT);
                                    window.history.replaceState({}, '', '/dashboard/article/' + value);
                                }} variant="outlined" color="primary" />
                        </Box>
                    }
                    
                </div>
            {
                currentARTICLE !== null ?
                    <section className='information'>
                        <div className='information_content'>
                            <div className='information_content_close'>
                                <button onClick={(e) => {
                                    setCurrentARTICLE(null);
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="information_content_head">
                                <div className="information_content_head_id"></div>
                            </div>

                            <form action='#' method='POST' className='form' name="actualityForm"
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                    if(actionType==="add") {
                                        _add_();
                                    } else {
                                        _update_();
                                    }
                                }}
                            >
                                <div className='form_input'>
                                <div className='form_input_title'>
                                        <span>Lien du logo</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={currentARTICLE.features.logoUrl} name='logoUrl' required placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setCurrentARTICLE((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "features": {
                                                            'logoUrl' : e.target.value
                                                        }
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>L'url du logo du produit</span>
                                    </div>
                                    {
                                        currentARTICLE.features.logoUrl ?
                                            <div className='form_input_image'>
                                                <img src={currentARTICLE.features.logoUrl} alt={currentARTICLE.name}/>
                                            </div>
                                        :
                                            null
                                    }
                                    
                                </div>
                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>NOM</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={currentARTICLE.name?currentARTICLE.name:""} name='name' required placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setCurrentARTICLE((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "name": e.target.value
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Le titre du produit</span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>DEVISE</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={currentARTICLE.currency?currentARTICLE.currency:""} name='currency' required placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setCurrentARTICLE((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "currency": e.target.value
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>La devise</span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>PRIX</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={currentARTICLE.price?currentARTICLE.price:""} name='price' required placeholder='...' type='number'
                                            onChange={(e)=>{
                                                setCurrentARTICLE((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "price": e.target.value
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>Le prix du produit</span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>SOURCE</span>
                                    </div>
                                    <div className='form_input_balise'>
                                        <input value={currentARTICLE.sourceUrl?currentARTICLE.sourceUrl:""} name='sourceUrl' required placeholder='...' type='text'
                                            onChange={(e)=>{
                                                setCurrentARTICLE((prev)=>{
                                                    return({
                                                        ...prev,
                                                        "sourceUrl": e.target.value
                                                    });
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='form_input_subText'>
                                        <span>La source du produit</span>
                                    </div>
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>CATÉGORIES</span>
                                    </div>
                                    {
                                        CATEGORY.map((cat, index) => (
                                            (
                                                actionType==="add" ?
                                                <div className='form_input_box'>
                                                    <input id={"cat" + cat.name} name='category' type='checkbox'/>
                                                    <label style={{marginLeft: "5px"}} htmlFor={"cat" + cat.name}>{cat.name}</label>
                                                </div>
                                            :
                                                currentARTICLE.categories &&
                                                <div className='form_input_box'>
                                                    <input defaultChecked={currentARTICLE.categories.some(item => item.category.name === cat.name)} id={"cat" + cat.name} name='category' type='checkbox'/>
                                                    <label style={{marginLeft: "5px"}} htmlFor={"cat" + cat.name}>{cat.name}</label>
                                                </div>
                                            )
                                        ))
                                    }
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Marque</span>
                                    </div>
                                    {
                                        BRAND.map((cat, index) => (
                                            (
                                                actionType==="add" ?
                                                <div className='form_input_box'>
                                                    <input id={"brand" + cat.name} name='brand' type='checkbox'/>
                                                    <label style={{marginLeft: "5px"}} htmlFor={"brand" + cat.name}>{cat.name}</label>
                                                </div>
                                            :
                                                currentARTICLE.brands &&
                                                <div className='form_input_box'>
                                                    <input defaultChecked={currentARTICLE.brands.some(item => item.brand.name === cat.name)} id={"brand" + cat.name} name='brand' type='checkbox'/>
                                                    <label style={{marginLeft: "5px"}} htmlFor={"brand" + cat.name}>{cat.name}</label>
                                                </div>
                                            )
                                        ))
                                    }
                                </div>

                                <div className='form_input'>
                                    <div className='form_input_title'>
                                        <span>Entreprise</span>
                                    </div>
                                    {
                                        COMPANY.map((cat, index) => (
                                            (
                                                actionType==="add" ?
                                                <div className='form_input_box'>
                                                    <input id={"company" + cat.name} name='company' type='radio'/>
                                                    <label style={{marginLeft: "5px"}} htmlFor={"company" + cat.name}>{cat.name}</label>
                                                </div>
                                            :
                                                currentARTICLE.company &&
                                                <div className='form_input_box'>
                                                    <input defaultChecked={currentARTICLE.company.name === cat.name} id={"company" + cat.name} name='company' type='radio'/>
                                                    <label style={{marginLeft: "5px"}} htmlFor={"company" + cat.name}>{cat.name}</label>
                                                </div>
                                            )
                                        ))
                                    }
                                </div>

                                <div className='form_button'>
                                    <button>
                                        VALIDER
                                    </button>
                                </div>
                            </form>
                        </div>
                    </section>
                :
                    null
            }
            </div>
        </div>
    );
}

export default DashboardAdminArticle;