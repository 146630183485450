import Footer from "../components/Footer"
import Header from "../components/Header"

// File
import tab3 from '../images/tabs-3.jpg'
import image2 from '../images/2.jpg'
import image3 from '../images/3.jpg'
import image8 from '../images/8.jpg'

function About() {
    return (
        <div>
            {/* <!-- ======= Header Section ======= --> */}
            <Header active={"aPropos"} />
            {/* <!-- End Header --> */}

            {/* <!-- ======= Main Section ======= --> */}
            <main id="main">

                {/* <!-- ======= About Section ======= --> */}
                <section id="tabs" class="tabs">
                    <div class="section-title mt-5">
                        <h2> Snobe </h2>
                        <p> Nous croyons en la qualité, l'authenticité et la transparence dans tout ce que nous faisons. </p>
                    </div>
                    <div class="container" data-aos="fade-up">
                        <ul class="nav nav-tabs row d-flex">
                            <li class="nav-item col-3">
                                <p class="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-1">
                                    <i class="ri-gps-line"></i>
                                    <h4 class="d-none d-lg-block"> Chez Snobe </h4>
                                </p>
                            </li>
                            <li class="nav-item col-3">
                                <p class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">
                                    <i class="ri-body-scan-line"></i>
                                    <h4 class="d-none d-lg-block">Expertise en Beauté et Mode</h4>
                                </p>
                            </li>
                            <li class="nav-item col-3">
                                <p class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3">
                                    <i class="ri-sun-line"></i>
                                    <h4 class="d-none d-lg-block">Sélection de Produits de Qualité </h4>
                                </p>
                            </li>
                            <li class="nav-item col-3">
                                <p class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-4">
                                    <i class="ri-store-line"></i>
                                    <h4 class="d-none d-lg-block">Engagement envers l'Expérience Client</h4>
                                </p>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane active show" id="tab-1">
                                <div class="row">
                                    <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up" data-aos-delay="100">
                                        <h3> Vous êtes passionné(e) par la beauté et la mode ? Vous cherchez à découvrir les derniers produits
                                            et
                                            tendances, tout en bénéficiant de recommandations fiables et de conseils d'experts ?</h3>
                                        <p class="fst-italic">
                                            Bienvenue dans l'univers de beauté et de la mode, où vous trouverez tout cela et bien plus encore.
                                        </p>
                                        <ul>
                                            <li><i class="ri-check-double-line"></i> Expertise en Beauté et Mode </li>
                                            <li><i class="ri-check-double-line"></i> Sélection de Produits de Qualité </li>
                                            <li><i class="ri-check-double-line"></i> Engagement envers l'Expérience Client </li>
                                        </ul>
                                        <p>
                                            Prêt(e) à explorer le monde excitant de la beauté et de la mode avec nous ? Rejoignez-nous dès
                                            maintenant et laissez-vous inspirer par tout ce que nous avons à vous offrir ! <br />
                                        </p>
                                        <a href="#about" class="get-started-btn scrollto"> Passer à l'action </a>
                                    </div>
                                    <div class="col-lg-6 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200">
                                        <img src={image8} alt="" class="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="tab-2">
                                <div class="row">
                                    <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                                        <h3>Expertise en Beauté et Mode</h3>
                                        <p>
                                            Nous sommes votre guichet unique pour tout ce qui concerne la beauté et la mode. Notre équipe
                                            d'experts est
                                            constamment à l'affût des dernières nouveautés et tendances, et nous mettons tout en œuvre pour vous
                                            fournir des recommandations avisées et des conseils pratiques.
                                        </p>
                                        <p class="fst-italic">
                                            Notre mission est de vous aider à trouver les meilleurs produits qui correspondent à votre style et à
                                            vos besoins.
                                        </p>
                                        <ul>
                                            <li><i class="ri-check-double-line"></i>Large sélection de produits de qualité</li>
                                            <li><i class="ri-check-double-line"></i>Conseils personnalisés et recommandations avisées</li>
                                            <li><i class="ri-check-double-line"></i>Actualités et tendances de la beauté et de la mode</li>
                                            <li><i class="ri-check-double-line"></i>Engagement envers la satisfaction client</li>
                                        </ul>
                                        <p>
                                            Prêt(e) à explorer le monde excitant de la beauté et de la mode avec nous ? Rejoignez-nous dès
                                            maintenant et laissez-vous inspirer par tout ce que nous avons à vous offrir ! <br />
                                        </p>
                                        <a href="#about" class="get-started-btn scrollto"> Passer à l'action </a>
                                    </div>
                                    <div class="col-lg-6 order-1 order-lg-2 text-center">
                                        <img src={image2} alt="" class="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="tab-3">
                                <div class="row">
                                    <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                                        <h3>Partenariat avec des Marques de Confiance</h3>
                                        <p>
                                            Nous collaborons étroitement avec des partenaires de confiance pour vous proposer une sélection de
                                            produits de qualité supérieure. Que vous recherchiez des produits de soin, des vêtements tendance ou
                                            des accessoires de mode, nous avons ce qu'il vous faut.
                                        </p>
                                        <ul>
                                            <li><i class="ri-check-double-line"></i>Produits de qualité supérieure</li>
                                            <li><i class="ri-check-double-line"></i>Large gamme de choix</li>
                                            <li><i class="ri-check-double-line"></i>Collaboration avec des marques renommées</li>
                                        </ul>
                                        <p class="fst-italic">
                                            Notre engagement envers la satisfaction client est notre priorité absolue.
                                        </p>
                                        <p>
                                            Prêt(e) à explorer le monde excitant de la beauté et de la mode avec nous ? Rejoignez-nous dès
                                            maintenant et laissez-vous inspirer par tout ce que nous avons à vous offrir ! <br />
                                        </p>
                                        <a href="#about" class="get-started-btn scrollto"> Passer à l'action </a>
                                    </div>
                                    <div class="col-lg-6 order-1 order-lg-2 text-center">
                                        <img src={tab3} alt="" class="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="tab-4">
                                <div class="row">
                                    <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                                        <h3>Engagement envers Votre Satisfaction</h3>
                                        <p>
                                            Votre satisfaction est notre priorité absolue. Nous nous engageons à vous offrir une expérience client
                                            exceptionnelle, en vous proposant un site convivial, des recommandations personnalisées et un service
                                            client réactif. Votre confiance et votre fidélité sont notre plus grande récompense.
                                        </p>
                                        <p class="fst-italic">
                                            Nous sommes déterminés à dépasser vos attentes à chaque étape de votre parcours avec nous.
                                        </p>
                                        <ul>
                                            <li><i class="ri-check-double-line"></i>Expérience client exceptionnelle</li>
                                            <li><i class="ri-check-double-line"></i>Recommandations personnalisées</li>
                                            <li><i class="ri-check-double-line"></i>Service client réactif</li>
                                        </ul>
                                        <p>
                                            Prêt(e) à explorer le monde excitant de la beauté et de la mode avec nous ? Rejoignez-nous dès
                                            maintenant et laissez-vous inspirer par tout ce que nous avons à vous offrir ! <br />
                                        </p>
                                        <a href="#about" class="get-started-btn scrollto"> Passer à l'action </a>
                                    </div>
                                    <div class="col-lg-6 order-1 order-lg-2 text-center">
                                        <img src={image3} alt="" class="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- End About Section --> */}

            </main>
            {/* <!-- End Main Section --> */}

            {/* <!-- ======= Footer Section ======= --> */}
            <Footer />
            {/* <!-- End Footer --> */}

            <a href="/" class="back-to-top d-flex align-items-center justify-content-center"><i
                class="bi bi-arrow-up-short"></i></a>
        </div>
    )
}

export default About