import { useState } from "react";
import Footer from "../components/Footer"
import Header from "../components/Header"
import Product from "../components/Product"
import { listProduit } from "../components/listProduit"


function Produits() {

    const [sortedListProduit, setSortedListProduit] = useState(listProduit);

    function sortProductWithSelect(selectValue) {
        switch (selectValue) {
            case "tout":
                setSortedListProduit(listProduit);
                break;
            case "beaute":
                setSortedListProduit(listProduit.filter((element) => element.categorie === "Beauté/Santé").sort((element1, element2) => element1.prix - element2.prix));
                break;
            case "femme":
                setSortedListProduit(listProduit.filter((element) => element.categorie === "Mode Femme").sort((element1, element2) => element1.prix - element2.prix));
                break;
            case "enfant":
                setSortedListProduit(listProduit.filter((element) => element.categorie === "Mode Enfant").sort((element1, element2) => element1.prix - element2.prix));
                break;
            case "homme":
                setSortedListProduit(listProduit.filter((element) => element.categorie === "Mode Homme").sort((element1, element2) => element1.prix - element2.prix));
                break;
            case "tendance":
                setSortedListProduit(listProduit.filter((element) => element.categorie === "Produits Tendances").sort((element1, element2) => element1.prix - element2.prix));
                break;
            case "decos":
                setSortedListProduit(listProduit.filter((element) => element.categorie === "Meilleures Décos").sort((element1, element2) => element1.prix - element2.prix));
                break;
            default:
                alert("Cette catégorie n'existe pas!");
                break;
        }
    }

    function sortProductWithSearch(value) {
        if (value === "") {
            setSortedListProduit(listProduit);
        } else {
            setSortedListProduit(listProduit.filter(element => (element.nom.toLowerCase()).startsWith(value.toLowerCase())));
        }
    }

    return (

        <div>
            {/* <!-- ======= Header Section ======= --> */}
            <Header active={"comparatif"} />
            {/* <!-- End Header --> */}

            {/* <!-- ======= Main Section ======= --> */}
            <main id="main">

                {/* <!-- ======= Product Section ======= --> */}
                <section id="portfolio" class="portfolio" data-aos="fade-up">
                    <div class="section-title mt-5">
                        <h2> Produits </h2>
                        <p>
                            Que vous le mascara longue tenue ou la robe de
                            soirée idéale, cette section est là pour vous aider à prendre la
                            meilleure décision d'achat.
                        </p>
                    </div>
                    <div class="card shadow mx-auto px-1 py-2 d-flex flex-row justify-content-between row gx-5 col-6">
                        <div class="col">
                            <div class="card d-flex flex-row justify-content-center align-items-center">
                                <span class="material-symbols-outlined"> search </span>
                                <input type="search" name="search" id="search" placeholder="Rechercher..." class="form-control border-0" onInput={(e) => sortProductWithSearch(e.target.value)} />
                            </div>
                        </div>
                        <div class="col">
                            <div className="d-flex justify-content-center align-items-center">
                                <p className="my-0 mx-2"> Catégorie </p>
                                <select name="selectByCategorie" id="selectByCategorie" class="form-select" onClick={(e) => sortProductWithSelect(e.target.value)}>
                                    <option value="tout" selected> Tout </option>
                                    <option value="beaute"> Beauté/Santé </option>
                                    <option value="femme"> Mode Femme </option>
                                    <option value="enfant"> Mode Enfant </option>
                                    <option value="homme"> Mode Homme </option>
                                    <option value="tendance"> Produits Tendances </option>
                                    <option value="decos"> Meilleures Décos </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div id="portfolio" class="portfolio mt-3">
                        <div class="container" data-aos="fade-up">
                            <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
                                {
                                    sortedListProduit.map((element, index) => <Product key={index} nom={element.nom} categorie={element.categorie} image={element.image} prix={element.prix} />)
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- End Product Section --> */}

            </main>
            {/* <!-- End Main Section --> */}

            {/* <!-- ======= Footer Section ======= --> */}
            <Footer />
            {/* <!-- End Footer --> */}

            <a href="/" class="back-to-top d-flex align-items-center justify-content-center"><i
                class="bi bi-arrow-up-short"></i></a>
        </div>
    )
}

export default Produits