

function Footer() {
    return (
        <div>
            <footer id="footer">
                <div class="footer-top">
                    <div class="container">
                        <div class="row justify-content-between">

                            <div class="col-lg-3 col-md-6 footer-contact">
                                <h3>Snobe<span>.</span></h3>
                                <p>
                                    <strong>Téléphone:</strong> +229 -- -- -- -- <br />
                                    <strong>Adresse e-mail:</strong> snobe.info@gmail.com<br />
                                </p>
                            </div>

                            <div class="col-lg-4 col-md-6 footer-links">
                                <h4>Liens utiles</h4>
                                <ul>
                                    <li><i class="bx bx-chevron-right"></i> <a href="/">Acceuil</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="/about">À propos</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="/comparatif">Comparatifs</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="/condition">Conditions d'utilisation</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="/politique">Politique de confidentialité</a></li>
                                </ul>
                            </div>

                            <div class="col-lg-4 col-md-6 footer-newsletter">
                                <h4>Rejoignez notre newsletter</h4>
                                <p> Entrez votre adresse e-mail pour restez à jour avec les dernières tendances </p>
                                <form action="" method="post">
                                    <input type="email" name="email" /><input type="submit" value="S'abonner" />
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="container d-md-flex py-4">
                    <div class="me-md-auto text-center text-md-start">
                        <div class="copyright">
                            &copy; Copyright <strong><span>Snobe</span></strong>. Tous droits réservés
                        </div>
                    </div>
                    <div class="social-links text-center text-md-end pt-3 pt-md-0">
                        <a href="/" class="twitter"><i class="bx bxl-twitter"></i></a>
                        <a href="/" class="facebook"><i class="bx bxl-facebook"></i></a>
                        <a href="/" class="instagram"><i class="bx bxl-instagram"></i></a>
                        <a href="/" class="google-plus"><i class="bx bxl-skype"></i></a>
                        <a href="/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer