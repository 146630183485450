import * as React from 'react';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import CardOverflow from '@mui/joy/CardOverflow';
import Chip from '@mui/joy/Chip';
import Link from '@mui/joy/Link';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';


function Product({ nom, categories, brands, prix, image, source }) {
    // console.log(categories);
    return (
        <Card sx={{ width: '100%', maxWidth: '100%', boxShadow: 'lg' }}>
            <CardOverflow>
                <AspectRatio sx={{ minWidth: 200 }}>
                <img
                    src={image}
                    srcSet={image}
                    loading="lazy"
                    alt=""
                />
                </AspectRatio>
            </CardOverflow>
            <Typography level="body-xs">
                {
                    brands.map((category, index) => (
                        <Link
                            href={category.brand.url}
                            fontWeight="md"
                            color="neutral"
                            textColor="text.primary"
                            target='_blank'
                            rel='noreferrer'
                            style={{color: "green"}}
                        >
                            {category.brand.name}
                        </Link>
                    ))
                }
            </Typography>
            <CardContent>
                <Typography level="body-xs">{nom}</Typography>
                    {
                        categories.map((category, index) => (
                            <Link
                                href={source}
                                fontWeight="md"
                                color="neutral"
                                textColor="text.primary"
                                endDecorator={<ArrowOutwardIcon />}
                                target='_blank'
                                rel='noreferrer'
                            >
                                {category.category.name}
                            </Link>
                        ))
                    }
                <Typography
                level="title-lg"
                sx={{ mt: 1, fontWeight: 'x' }}
                endDecorator={
                    <Chip component="span" size="sm" variant="soft" color="success">
                    Lowest price
                    </Chip>
                }
                >
                    {prix}
                </Typography>
            </CardContent>
            <CardOverflow>
                <Button variant="solid" color="danger" size="lg">
                    <Link
                        href={source}
                        fontWeight="md"
                        color="white"
                        textColor="text.primary"
                        overlay
                        target='_blank'
                        rel='noreferrer'
                        style={{color: "#ffffffff"}}
                    >
                        En savoir plus
                    </Link>
                </Button>
            </CardOverflow>
        </Card>
    )
}

export default Product