import axios from "axios";
import PATH_ from '../GLOBAL-VAR/globals-urls';

const PATH__ = new PATH_();
const currentUser = JSON.parse(localStorage.getItem("snobe_user"));

class BrandRequest {
    add(data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "brands",
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    linkMedia(data, brandId, mediaId) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "brands/" + brandId + "/medias?media=" + mediaId,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    unlinkMedia(brandId, mediaIndex) {
        return (
            axios({
                method: 'delete',
                url: PATH__.api_root + "brands/" + brandId + "/medias/" + mediaIndex,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    GetAll(limit = 20, page = 1) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "brands/?limit=" + limit + "&page=" + page,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    GetOne(id) {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "brands/" + id,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    // update(id, data, image) {
    //     return (
    //         axios({
    //             method: 'patch',
    //             data: data,
    //             url: image !== null ? PATH__.api_root + "brands/" + id + "?image=" + image : PATH__.api_root + "brands/" + id,
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'U-Authorization': 'Bearer ' + currentUser.key.authKey,
    //             },
    //         })
    //     )
    // }

    update(id, data) {
        return (
            axios({
                method: 'patch',
                data: data,
                url: PATH__.api_root + "brands/" + id,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }

    delete(id) {
        return (
            axios({
                method: 'delete',
                url: PATH__.api_root + "brands/" + id,
                headers: {
                    'Content-Type': 'application/json',
                    'U-Authorization': 'Bearer ' + currentUser.key.authKey,
                },
            })
        )
    }
}

export default BrandRequest;