import image1 from '../images/1.jpg'
import image2 from '../images/2.jpg'
import image3 from '../images/3.jpg'
import image4 from '../images/4.jpg'
import image5 from '../images/5.jpg'
import image6 from '../images/6.jpg'

export const listProduit = [
    {
        nom: "Crème anti-rides",
        categorie: "Beauté/Santé",
        prix: 29.99,
        image: image1
    },
    {
        nom: "Robe de soirée élégante",
        categorie: "Mode Femme",
        prix: 99.99,
        image: image2
    },
    {
        nom: "Chaussures de sport pour enfants",
        categorie: "Mode Enfant",
        prix: 49.99,
        image: image3
    },
    {
        nom: "Chemise en lin pour hommes",
        categorie: "Mode Homme",
        prix: 59.99,
        image: image4
    },
    {
        nom: "Lunettes de soleil tendance",
        categorie: "Produits Tendances",
        prix: 39.99,
        image: image5
    },
    {
        nom: "Cadre photo mural",
        categorie: "Meilleures Décos",
        prix: 19.99,
        image: image6
    },
    {
        nom: "Soin du visage hydratant",
        categorie: "Beauté/Santé",
        prix: 19.99,
        image: image1
    },
    {
        nom: "Jupe plissée pour femmes",
        categorie: "Mode Femme",
        prix: 59.99,
        image: image2
    },
    {
        nom: "Chemise en lin pour hommes",
        categorie: "Mode Homme",
        prix: 59.99,
        image: image3
    },
];
